<template>
  <div class="topnav">
    <div class="container-fluid">
      <nav class="navbar navbar-dark navbar-expand-lg topnav-menu">
        <div class="collapse navbar-collapse" id="topnav-menu-content">
          <ul class="navbar-nav m-auto">
            <!-- Home -->
            <li class="nav-item dropdown">
              <router-link
                to="/home"
                class="nav-link dropdown-toggle arrow-none"
                title="Home"
              >
                <i class="bx ri-home-line mr-2"></i>
                Home
              </router-link>
            </li>

            <!-- Regulamento -->
            <li class="nav-item dropdown">
              <a
                href="https://goidigital.com.br/torneiodevolei/regulamento_torneio.pdf"
                class="nav-link dropdown-toggle arrow-none"
                target="_blank"
              >
                <i class="bx ri-file-paper-2-line mr-2"></i>
                Regulamento
              </a>
            </li>

            <!-- Inscricao -->
            <li class="nav-item">
              <router-link
                to="/inscricao"
                class="nav-link"
                title="Inscrição de Times"
              >
                <i class="bx ri-team-line mr-2"></i>
                Inscrição de time
              </router-link>
            </li>

            <!-- TIMES INSCRITOS -->
            <li
              class="nav-item dropdown"
              v-if="isLoggedIn && getModuleUserByCode('0.01')"
            >
              <router-link
                to="/administration/times"
                class="nav-link dropdown-toggle arrow-none"
                title="Times Inscritos"
              >
                <i class="bx ri-account-box-line mr-2"></i>
                Times Inscritos
              </router-link>
            </li>

            <!-- JOGOS -->
            <li
              class="nav-item dropdown"
              v-if="isLoggedIn && getModuleUserByCode('1.01')"
            >
              <router-link
                to="/administration/chaves"
                class="nav-link dropdown-toggle arrow-none"
                title="Tabela de Jogos"
              >
                <i class="bx ri-account-box-line mr-2"></i>
                Tabela de Jogos
              </router-link>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'NavbarHorizontal',

  computed: {
    ...mapGetters(['getModuleUserByCode']),
    ...mapGetters(['isLoggedIn']),
  },

  methods: {
    onMenuClick(event) {
      event.preventDefault()
      const nextEl = event.target.nextSibling
      if (nextEl && !nextEl.classList.contains('show')) {
        const parentEl = event.target.parentNode
        if (parentEl) {
          parentEl.classList.remove('show')
        }
        nextEl.classList.add('show')
      } else if (nextEl) {
        nextEl.classList.remove('show')
      }
      return false
    },
  },

  mounted() {
    const links = document.getElementsByClassName('side-nav-link-ref')
    let matchingMenuItem = null

    for (let i = 0; i < links.length; i += 1) {
      if (
        window.location.pathname === links[i].pathname ||
        window.location.pathname.includes(links[i].pathname)
      ) {
        matchingMenuItem = links[i]
        break
      }
    }

    if (matchingMenuItem) {
      matchingMenuItem.classList.add('active')
      const parent = matchingMenuItem.parentElement
      if (parent) {
        parent.classList.add('active')
        const parent2 = parent.parentElement
        if (parent2) {
          parent2.classList.add('active')
        }
        const parent3 = parent2.parentElement
        if (parent3) {
          parent3.classList.add('active')
          const childAnchor = parent3.querySelector('.has-dropdown')
          if (childAnchor) childAnchor.classList.add('active')
        }

        const parent4 = parent3.parentElement
        if (parent4) parent4.classList.add('active')
        const parent5 = parent4.parentElement
        if (parent5) parent5.classList.add('active')
      }
    }
  },
}
</script>
